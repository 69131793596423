






















































































import { defineComponent } from '@nuxtjs/composition-api';
import { PropType } from 'vue';

interface IBlok {
  label: string;
  firstContent: any;
  firstImage: any;
  secondImage: any;
  secondContent: any;
}

export default defineComponent({
  name: 'AboutUsSection',
  props: {
    blok: {
      type: Object as PropType<IBlok>,
      required: true,
    },
  },
});
